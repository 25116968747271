<template>
  <q-form ref="editForm">
    <c-card title="상세" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn 
            v-if="editable && !isOld" 
            label="불러오기" 
            icon="system_update_alt"
            @btnClicked="loadAssessPlan" />
          <c-btn 
            v-if="editable && !disabled" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="assessPlan"
            :mappingType="mappingType"
            label="저장" 
            icon="save"
            @beforeAction="saveAssessPlan"
            @btnCallback="saveCallback" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-text
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            label="평가명"
            name="assessmentName"
            v-model="assessPlan.assessmentName">
          </c-text>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            :range="true"
            label="평가기간"
            name="period"
            v-model="assessPlan.assessmentPeriod"
          />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            type="year"
            label="평가년도"
            name="assessmentYear"
            v-model="assessPlan.assessmentYear"
          />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            codeGroupCd="RAM_ASSESS_TYPE_CD"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="ramAssessTypeCd"
            label="평가구분-정기/수시/최초"
            v-model="assessPlan.ramAssessTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            :comboItems="matrixItems"
            type="edit"
            itemText="matrixName"
            itemValue="ramMatrixId"
            name="ramMatrixId"
            label="Matrix"
            v-model="assessPlan.ramMatrixId"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-field 
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            :data="assessPlan"
            deptValue="assessmentManageDeptCd"
            type="dept_user" 
            label="주관" 
            name="assessmentManageUserId" 
            v-model="assessPlan.assessmentManageUserId" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-plant 
            :required="true" 
            :disabled="disabled"
            :editable="editable" 
            type="edit" 
            name="plantCd" 
            v-model="assessPlan.plantCd" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :editable="editable"
            :disabled="true"
            label="작성자/작성일"
            name="request"
            v-model="request">
          </c-text>
        </div>
        <div class="col-12">
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            label="상세내용"
            name="remark"
            v-model="assessPlan.remark">
          </c-textarea>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-field 
            :editable="editable"
            :disabled="disabled"
            :data="assessPlan"
            type="user" 
            label="검토자" 
            name="reviewUserId" 
            v-model="assessPlan.reviewUserId" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-field 
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            :data="assessPlan"
            type="user" 
            userType="user"
            label="승인자" 
            name="approvalUserId" 
            v-model="assessPlan.approvalUserId" />
        </div>
        <div v-if="isOld" class="col-12">
          <c-table
            ref="table1"
            title="평가팀 목록"
            :columns="grid.columns"
            :gridHeight="grid.height"
            :data="assessPlan.teams"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            :editable="editable&&!disabled"
            selection="multiple"
            rowKey="ramAssessmentTeamId"
          >
            <!-- 버튼 영역 -->
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn 
                  v-if="editable&&!disabled" 
                  :showLoading="false" 
                  label="추가" 
                  icon="add" 
                  @btnClicked="addTeam" />
                <c-btn 
                  v-if="editable&&!disabled" 
                  :disabled="!assessPlan.teams || assessPlan.teams.length === 0"
                  label="삭제" 
                  icon="remove" 
                  @btnClicked="deleteAssessTeam" />
                <c-btn 
                  v-if="editable && !disabled" 
                  :disabled="!assessPlan.teams || assessPlan.teams.length === 0"
                  :isSubmit="isSaveTeam"
                  :url="saveTeamUrl"
                  :param="assessPlan.teams"
                  mappingType="PUT"
                  label="저장" 
                  icon="save"
                  @beforeAction="saveAssessTeam"
                  @btnCallback="saveTeamCallback" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
        <div class="col-12">
          <c-upload 
            :attachInfo="attachInfo"
            :editable="editable&&!disabled">
          </c-upload>
        </div>
      </template>
    </c-card>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'fm-plan-info',
  props: {
    assessPlan: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        plantCd: '',  // 사업장 코드
        ramTechniqueCd: '',  // 평가기법 코드_HAZOP/KRAS/JSA/CHECKLIST
        ramAssessTypeCd: '',  // 평가구분_최초/정기/수시
        assessmentName: '',  // 평가명
        assessmentStartDate: '',  // 평가시작일
        assessmentEndDate: '',  // 평가종료일
        assessmentPeriod: [],
        assessmentYear: '',  // 평가년도
        ramStepCd: '',  // 평가 진행단계 코드
        assessmentManageDeptCd: '',  // 평가주관 부서 코드
        assessmentManageUserId: '',  // 평가주관 담당자 ID
        ramMatrixId: null,  // 위험메트릭스 번호
        sopMocId: '',  // MOC 일련번호
        remark: '',  // 상세내용
        regUserId: '',  // 작성자 ID
        regUserName: '',  // 작성자 ID
        reviewUserId: '',  // 검토자 ID
        reviewDate: '',  // 검토일
        approvalUserId: '',  // 승인자 ID
        approvalDate: '',  // 승인일
        chgUserId: '',  // 수정자 ID
      }),
    },
    attachInfo: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: '4M_PLAN',
        taskKey: '',
      }),
    },
    updateBtnData: {
      type: Object,
      default: () => ({
        flag: false,
      }),
    },
  },
  data() {
    return {
      matrixItems: [],
      grid: {
        columns: [],
        height: '250px'
      },
      editable: true,
      matrixListUrl: '',
      saveUrl: '',
      teamListUrl: '',
      saveTeamUrl: '',
      deleteTeamUrl: '',
      isSave: false,
      isSaveTeam: false,
      mappingType: 'POST',
      saveCallData: '',
      popupOptions: {
        isApproval: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  watch: {
    'assessPlan.plantCd'() {
      this.setMatrixItems(); 
    }
  },
  computed: {
    isOld() {
      return Boolean(this.assessPlan.ramRiskAssessmentPlanId)
    },
    request() {
      return this.assessPlan.regUserName + ' / ' + this.assessPlan.regDt
    },
    disabled() {
      return Boolean(this.assessPlan.ramStepCd) && this.assessPlan.ramStepCd !== 'R4RS00001' && !this.updateBtnData.flag
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.matrixListUrl = selectConfig.ram.matrix.list.url
      this.saveUrl = transactionConfig.ram.assessPlan.insert.url
      this.teamListUrl = selectConfig.ram.assessTeam.list.url;
      this.saveTeamUrl = transactionConfig.ram.assessTeam.update.url;
      this.deleteTeamUrl = transactionConfig.ram.assessTeam.delete.url;
      // code setting
      this.setMatrixItems(); 
      this.setTeamHeader(); 
      // list setting
    },
    setMatrixItems() {
      this.$http.url = this.matrixListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.assessPlan.plantCd,
        ramTechniqueCd: this.assessPlan.ramTechniqueCd,
      }
      this.$http.request((_result) => {
        this.matrixItems = _result.data;
      },);
    },
    setTeamHeader() {
      this.$comm.getComboItems('RAM_ROLE_TYPE_CD').then(role => {
        this.grid.columns = [
          {
            name: 'ramRoleTypeCd',
            field: 'ramRoleTypeCd',
            label: '역할',
            align: 'center',
            style: 'width:30%',
            type: 'select',
            comboItems: role,
            sortable: false,
            required: true,
          },
          {
            name: 'etcRole',
            field: 'etcRole',
            label: '기타 역할 - (역할이 \'기타\' 선택 될 시)',
            align: 'left',
            style: 'width:30%',
            type: 'text',
            sortable: false,
            disableTarget: 'ramRoleTypeCd',
            disableCon: 'RRT0000010',
          },
          {
            name: 'userName',
            field: 'userName',
            label: '성명',
            align: 'center',
            style: 'width:40%',
            type: 'text',
            sortable: false,
            required: true,
          },
        ];
      });
    },
    getTeams() {
      this.$http.url = this.teamListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.assessPlan.ramRiskAssessmentPlanId
      }
      this.$http.request((_result) => {
        this.assessPlan.teams = _result.data
      },);
    },
    loadAssessPlan() {
      this.popupOptions.title = "불러오기"; // 불러오기
      this.popupOptions.param = {
        type: "single",
        ramTechniqueCd: 'RT00000025',
        regUserId: this.$store.getters.user.userId
      };
      this.popupOptions.target = () => import(`${"@/pages/ram/loadAssessmentPop.vue"}`);
      this.popupOptions.width = "70%";
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeLoadPopup;
    },
    closeLoadPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$emit('loadAssess', data[0].ramRiskAssessmentPlanId)
      }
    },
    saveAssessPlan() {
      if (this.assessPlan.ramRiskAssessmentPlanId) {
        this.saveUrl = transactionConfig.ram.assessPlan.update.url
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.ram.assessPlan.insert.url
        this.mappingType = 'POST';
        this.assessPlan.ramStepCd = 'R4RS00001' // 평가계획
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.assessPlan.regUserId = this.$store.getters.user.userId
              this.assessPlan.chgUserId = this.$store.getters.user.userId

              this.assessPlan.assessmentStartDate = this.assessPlan.assessmentPeriod[0]
              this.assessPlan.assessmentEndDate = this.assessPlan.assessmentPeriod[1]
              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.attachInfo, 'taskKey', result.data)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.saveCallData = uid();
      this.$emit('saveCallback', result.data)
    },
    addTeam() {
      this.assessPlan.teams.splice(0, 0, {
        ramAssessmentTeamId: uid(),
        ramInsideOutsideCd: '1',
        ramRoleTypeCd: null, userId: '', userName: '', deptName: '', etcRole: '',
        editFlag: 'C', regUserId: this.$store.getters.user.userId,
        ramRiskAssessmentPlanId: this.assessPlan.ramRiskAssessmentPlanId
      })
    },
    deleteAssessTeam() {
      let selectData = this.$refs['table1'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$http.url = this.deleteTeamUrl;
        this.$http.type = 'DELETE';
        this.$http.param = {
          data: Object.values(selectData),
        };
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          
          this.$_.forEach(selectData, item => {
            this.assessPlan.teams = this.$_.reject(this.assessPlan.teams, item);
          })
          this.$refs['table1'].$refs['compo-table'].clearSelection();
          // this.getTeams();
        },);
      }
    },
    saveAssessTeam() {
      if (this.$comm.validTable(this.grid.columns, this.assessPlan.teams)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.assessPlan.teams, item => {
              item.chgUserId = this.$store.getters.user.userId
            })

            this.isSaveTeam = !this.isSaveTeam
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveTeamCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getTeams();
    },
  }
};
</script>
